import React from "react";
import { TextToImageGeneratePage, TextToImageRemixPageWrapper } from "./TextToImageGeneratePage";
import MenuBar from "./MenuBar";
import './InvisibleAuthenticator.css';
import { RequireAuth } from "./RequireAuth";
import {
    Authenticator,
} from "@aws-amplify/ui-react";
import ProductComponentViewWrapper from "./ProductComponentView";
import Favorites from "./Favorites";
import { Footer } from "./Footer";
import { ShoppingCartPage } from "./ShoppingCart";
import { useLocation } from "react-router-dom";
import { useCart } from "react-use-cart";
import SuccessPaymentPage from "./SuccessPaymentPage";

// We have a separate module for auth routes in order to utilize the "invisible" authenticator component
// If we would combine the css import with <Login /> route then the authenticator is not displayed
// even though we want it to be displayed.

export const TexttoImageAuthenticatedRoute = () => {
    return (
        <RequireAuth>
            <div>
                {
                    // Considered a hidden/invisible authenticator required because
                    // https://github.com/aws-amplify/amplify-ui/issues/1497#issuecomment-1064292547
                }
                <Authenticator></Authenticator>
                <MenuBar />
                <TextToImageGeneratePage />
            </div>
            <Footer />
        </RequireAuth>
    )
}

export const ProductAuthenticatedRoute = () => {
    return (
        <RequireAuth>
            <div>
                {
                    // Considered a hidden/invisible authenticator required because
                    // https://github.com/aws-amplify/amplify-ui/issues/1497#issuecomment-1064292547
                }
                <Authenticator></Authenticator>
                <MenuBar />
                <ProductComponentViewWrapper />
                <Footer />
            </div>
        </RequireAuth>
    )
}

export const FavoritesAuthenticatedRoute = () => {
    return (
        <RequireAuth>
            <div>
                {
                    // Considered a hidden/invisible authenticator required because
                    // https://github.com/aws-amplify/amplify-ui/issues/1497#issuecomment-1064292547
                }
                <Authenticator></Authenticator>
                <MenuBar />
                <Favorites />
                <Footer />
            </div>
        </RequireAuth>
    )
}

export const RemixAuthenticatedRoute = () => {
    return (
        <RequireAuth>
            <div>
                {
                    // Considered a hidden/invisible authenticator required because
                    // https://github.com/aws-amplify/amplify-ui/issues/1497#issuecomment-1064292547
                }
                <Authenticator></Authenticator>
                <MenuBar />
                <TextToImageRemixPageWrapper />
                <Footer />
            </div>
        </RequireAuth>
    )
}

export const ShoppingCartAuthenticatedRoute = () => {
    return (
        <RequireAuth>
            <div>
                {
                    // Considered a hidden/invisible authenticator required because
                    // https://github.com/aws-amplify/amplify-ui/issues/1497#issuecomment-1064292547
                }
                <Authenticator></Authenticator>
                <MenuBar />
                <ShoppingCartPage />
                <Footer />
            </div>
        </RequireAuth>
    )
}

export const SuccessPaymentAuthenticatedRoute = () => {
    const { emptyCart } = useCart();

    React.useEffect(() => {
        emptyCart();
    }, [])

    return (
        <RequireAuth>
            <div>
                {
                    // Considered a hidden/invisible authenticator required because
                    // https://github.com/aws-amplify/amplify-ui/issues/1497#issuecomment-1064292547
                }
                <Authenticator></Authenticator>
                <MenuBar />
                <SuccessPaymentPage />
                <Footer />
            </div>
        </RequireAuth>
    )
}