import { Box, Grid, Stack } from "@mui/material";
import { GeneratedImage } from "./models";
import React, { useState } from "react";
import ButtonBaseImageListView from "./ButtonBase";
import { getFavoriteGeneratedImages } from "./util";

const Favorites = () => {
  const [favoriteGeneratedImages, setFavoriteGeneratedImages] = useState<GeneratedImage[]>([]); // Initialize as an empty array

  const getFavorites = async () => {
    const favoriteImages = await getFavoriteGeneratedImages();
    setFavoriteGeneratedImages(favoriteImages ?? []);
  }

  const handleFavoriteGeneratedImageClick = (generatedImageID: string) => {
    const updatedFavorites = favoriteGeneratedImages.filter(generatedImage => generatedImage.id !== generatedImageID);
    setFavoriteGeneratedImages(updatedFavorites);
  }

  React.useEffect(() => {
    getFavorites();
  }, []); // Empty dependency array to run only once

  return (
    <Box m={2}>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="center"
      >
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Stack spacing={2} direction="column">
            {favoriteGeneratedImages
              ? <ButtonBaseImageListView
                onFavoriteButtonClick={handleFavoriteGeneratedImageClick}
                generatedImages={favoriteGeneratedImages}
                price={34.99} />
              : <div></div>}
          </Stack>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
    </Box>
  )
}

export default Favorites;
