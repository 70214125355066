import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { encodeBase64, getGeneratedImage } from './util'
import { AutoFixHigh, Favorite, FavoriteBorderOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { GeneratedImage } from './models';
import { DataStore } from 'aws-amplify';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

interface ButtonBaseImageListViewProps {
    onFavoriteButtonClick?: (generatedImageID: string) => void;
    generatedImages: GeneratedImage[];
    price: number;
}

// Find and replace a generatedImage with the given id
const replaceGeneratedImage = (generatedImages: GeneratedImage[], id: string, newGeneratedImage: GeneratedImage) => {
    const index = generatedImages.findIndex(generatedImage => generatedImage.id === id);

    if (index === -1) {
        // Person with given ID not found, return original array.
        return generatedImages;
    }

    return [
        ...generatedImages.slice(0, index),
        newGeneratedImage,
        ...generatedImages.slice(index + 1)
    ];
}

interface GeneratedImageDisplayButtonProps {
    onFavoriteButtonClick?: (generatedImageID: string) => void;
    generatedImage: GeneratedImage;
    price: number;
}


export const GeneratedImageDisplayButton = (props: GeneratedImageDisplayButtonProps) => {
    const [generatedImage, setGeneratedImage] = React.useState<GeneratedImage>(props.generatedImage);
    const navigate = useNavigate();

    const handleOnImageButtonClick = async (generatedImage: GeneratedImage, price: number) => {
        // We have to query the database again because sometimes the imageResult is not set
        const generatedImageResult = await getGeneratedImage(generatedImage.id);
        const result = await generatedImageResult!.imageResult;
        const options = { generatedImage: generatedImage, description: result?.prompt, price: price };
        // Set the current state as a path id param
        // Allows each product page to have a unique url
        navigate({
            pathname: `/generate/product/${encodeBase64(JSON.stringify(options))}`,
        });
    }

    React.useEffect(() => {
        setGeneratedImage(props.generatedImage)
        // ignore this warning because props.generatedImage.id is what we use to determine if the generatedImage has changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.generatedImage.id])

    return (
        <Paper key={generatedImage?.sourceUrl} elevation={3} sx={{ width: 300, height: 300, margin: 1 }}>
            <Stack spacing={1} direction="column">
                <ImageButton
                    onClick={() => handleOnImageButtonClick(generatedImage!, props.price)}
                    focusRipple
                    key={generatedImage?.sourceUrl}
                    style={{
                        width: 300,
                        height: 300
                    }}
                >
                    <ImageSrc style={{ backgroundImage: `url(${generatedImage?.sourceUrl})` }} />
                    <ImageBackdrop className="MuiImageBackdrop-root" />
                    <Image>
                        <Typography
                            component="span"
                            variant="subtitle1"
                            color="inherit"
                            sx={{
                                position: 'relative',
                                p: 4,
                                pt: 2,
                                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                            }}
                        >
                            View
                            <ImageMarked className="MuiImageMarked-root" />
                        </Typography>
                    </Image>
                </ImageButton>
            </Stack>
        </Paper>
    );
}


const ButtonBaseImageListView = (props: ButtonBaseImageListViewProps) => {
    const [generatedImages, setGeneratedImages] = React.useState<GeneratedImage[]>(props.generatedImages);
    const navigate = useNavigate();

    const handleOnImageButtonClick = async (generatedImage: GeneratedImage, price: number) => {
        const imageResult = await generatedImage.imageResult;
        const options = { generatedImage: generatedImage, description: imageResult?.prompt, price: price };
        // Set the current state as a path id param
        // Allows each product page to have a unique url
        navigate({
            pathname: `/generate/product/${encodeBase64(JSON.stringify(options))}`,
        });
    }

    const handleOnFavoriteButtonClick = async (generatedImage: GeneratedImage) => {
        const unsavedGeneratedImage = GeneratedImage.copyOf(generatedImage, updated => {
            // when isFavorite field is null then set it to true, otherwise, set it to the opposite of the existing value
            updated.isFavorite = generatedImage.isFavorite === null ? true : !generatedImage.isFavorite
        })
        const updatedGeneratedImage = await DataStore.save(unsavedGeneratedImage);
        // After updating the generated image we need to reset the generated images. In order to do that
        // we can manually update the list
        const updatedGeneratedImages = replaceGeneratedImage(generatedImages, generatedImage.id, updatedGeneratedImage)
        setGeneratedImages(updatedGeneratedImages)
        if (props.onFavoriteButtonClick) {
            props.onFavoriteButtonClick(updatedGeneratedImage.id)
        }
    }

    const handleOnRemixButtonClick = async (generatedImage: GeneratedImage) => {
        const imageResult = await generatedImage.imageResult;
        const options = { remixImage: generatedImage, prompt: imageResult!.prompt };
        // Set the current state as a path id param
        // Allows each product page to have a unique url
        navigate({
            pathname: `/generate/remix/${encodeBase64(JSON.stringify(options))}`,
        });
    }

    // useEffect uses a reference equality check on the dependency array values to determine whether to re-run the effect.
    // As a result, unless we tell it to only compare primitives, it will never re-run the effect if the dependency is an array, or an object.
    // Because this should only run when the generatedImages array changes, we need to use JSON.stringify to compare the values
    // it should be relatively simple to serialize the generatedImages which aren't expected to be large amounts of data
    React.useEffect(() => {
        setGeneratedImages([...props.generatedImages])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.generatedImages)])

    return (
        <Grid container spacing={2}>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
                {generatedImages.map((generatedImage) => (
                    <Paper key={generatedImage?.sourceUrl} elevation={3} sx={{ width: 300, height: 350, margin: 1 }}>
                        <Stack spacing={1} direction="column">
                            <ImageButton
                                onClick={() => handleOnImageButtonClick(generatedImage!, props.price)}
                                focusRipple
                                key={generatedImage?.sourceUrl}
                                style={{
                                    width: 300,
                                    height: 300
                                }}
                            >
                                <ImageSrc style={{ backgroundImage: `url(${generatedImage?.sourceUrl})` }} />
                                <ImageBackdrop className="MuiImageBackdrop-root" />
                                <Image>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        color="inherit"
                                        sx={{
                                            position: 'relative',
                                            p: 4,
                                            pt: 2,
                                            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                        }}
                                    >
                                        View
                                        <ImageMarked className="MuiImageMarked-root" />
                                    </Typography>
                                </Image>
                            </ImageButton>
                            {
                                // TODO: set this via a class
                                // style is inspired by other ecommmerce sites
                            }
                            <Grid container spacing={0} alignItems={"center"}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={7}>
                                    <Box style={
                                        { color: "#222", fontFamily: '"Graphik Webfont",-apple-system,"Helvetica Neue","Droid Sans",Arial,sans-serif', fontWeight: 700, fontSize: "16px", lineHeight: "20px" }}>
                                        ${props.price}+
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Tooltip title="Remix">
                                        <IconButton onClick={() => handleOnRemixButtonClick(generatedImage)} aria-label="Remix image button">
                                            <AutoFixHigh />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={2}>
                                    <Tooltip title="Favorite">
                                        <IconButton onClick={() => handleOnFavoriteButtonClick(generatedImage)} aria-label="favorite image button">
                                            {generatedImage.isFavorite
                                                ? <Favorite sx={{ color: "#a61a2e" }} />
                                                : <FavoriteBorderOutlined sx={{ color: "#222" }} />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Paper>
                ))}
            </Box>
        </Grid>
    );
}

export default ButtonBaseImageListView
