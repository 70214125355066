import { Box, Grid, Drawer, Collapse } from '@mui/material';
import React, { useState } from 'react';
import ClickableCard from './ClickableCard';
import { PromptActionButton } from './PromptActionButton';
import { isMobile } from 'react-device-detect';

interface AIGeneratedAlternativePromptsGridProps {
    alternativePrompts: string[];
    onClick: (body: string) => void;
}

export const AIGeneratedAlternativePromptsGrid = (props: AIGeneratedAlternativePromptsGridProps) => {
    const [open, setOpen] = useState(false);

    const handleClickableCardClick = (body: string) => {
        props.onClick(body);
        setOpen(false);
    }

    const drawerContent = (
        <Grid container spacing={2}>
            {props.alternativePrompts.map((alternativePrompt: string, index: number) => (
                <Grid item xs={12} md={6} key={index}>
                    <ClickableCard body={alternativePrompt} onClick={handleClickableCardClick} />
                </Grid>
            ))}
        </Grid>
    );

    return (
        <PromptActionButton
            buttonTooltipText="Explore alternative prompts generated by AI"
            buttonText="AI Suggestions"
            isOpen={open}
            toggleOpen={() => setOpen(!open)}
        >
            {isMobile ? (
                <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
                    {drawerContent}
                </Drawer>
            ) : (
                <Collapse in={open}>
                    {drawerContent}
                </Collapse>
            )}
        </PromptActionButton>
    );
};
