import React from 'react';
import { Box, ButtonBase, CircularProgress, Typography } from '@mui/material';
import Slider from 'react-slick';
import { Footer } from './Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/ReactSlick.css'
import { isMobile } from 'react-device-detect';
import { DataStore } from 'aws-amplify';
import { Category as DatastoreCategory, PublicImage } from './models';
import { useNavigate } from 'react-router-dom';
import { encodeBase64 } from './util';
import { LandingAppBar } from './LandingPage';


type Category = {
    name: string;
    images: PublicImage[];
}

export interface ImageCarouselProps {
    categories: Category[];
}

async function getCategorizedImages(): Promise<Category[]> {
    try {
        const categories = await DataStore.query(DatastoreCategory);

        const categorizedImages = await Promise.all(
            categories.map(async (category) => {
                const images = await category.publicImages.toArray();
                return {
                    name: category.name,
                    images: images,
                };
            })
        );
        return categorizedImages;
    } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
            console.error('Error getting categorized images:', error);
        }
        throw error;
    }
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ categories }) => {
    const navigate = useNavigate();
    const slidesToShow = Math.floor(window.innerWidth / (isMobile ? 100 : 250));
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: isMobile ? 2 : 1,
        arrows: !isMobile,
    };

    const handleOnImageClick = async (publicImage: PublicImage) => {
        const options = { publicImage: publicImage };
        // Set the current state as a path id param
        // Allows each product page to have a unique url
        navigate({
            pathname: `/generate/remix/${encodeBase64(JSON.stringify(options))}`,
        });
    }

    const imageSize = isMobile ? '20vw' : '10vw';

    return (
        <Box sx={{ m: 2 }}>
            {categories.map((category, index) => (
                <Box key={index} sx={{ m: 2 }}>
                    <Typography variant="h5" gutterBottom>{category.name}</Typography>
                    <Slider {...settings}>
                        {category.images.map((image, imgIndex) => (
                            <div key={imgIndex}>
                                <ButtonBase onClick={() => handleOnImageClick(image)}>
                                    <img
                                        src={image.sourceUrl}
                                        alt={image.prompt ?? ""}
                                        style={{ width: imageSize, height: imageSize }}
                                    />
                                </ButtonBase>
                            </div>
                        ))}
                    </Slider>
                </Box>
            ))}
        </Box>
    );
};

export default ImageCarousel;

export const ExplorePage: React.FC = () => {
    const [categories, setCategories] = React.useState<Category[]>([]);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const categorizedImages = await getCategorizedImages();
                setCategories(categorizedImages);
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error('An error occurred while fetching the categorized images:', error);
                }
                // Handle the error as needed in your application
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <LandingAppBar />
            {categories.length > 0 ? <ImageCarousel categories={categories} /> : <CircularProgress sx={{ m: 5 }} />}
            <Footer />
        </div>
    );
};
