import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Drawer, ImageListItemBar, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PromptHelperStyleImages } from '../models';
import { isMobile } from 'react-device-detect';

interface PromptHelperToolsDrawerProps {
    isOpen: boolean;
    handleDrawerClose: () => void;
    handleStyleImageChange: (styles: PromptHelperStyleImages[]) => void;
}

const useStyles = makeStyles((_theme: any) => ({
    drawerPaper: {
        height: isMobile ? '75vh' : '100vh',
        width: isMobile ? '100vw' : '500px',
    }
}));

export const PromptHelperToolsDrawer = (props: PromptHelperToolsDrawerProps) => {
    const [images, setImages] = useState<PromptHelperStyleImages[]>([]);  // To store the fetched images
    const [selectedImage, setSelectedImage] = useState<PromptHelperStyleImages | null>(null);
    const classes = useStyles();
    const imageListItemDimension = isMobile ? 100 : 200;

    useEffect(() => {
        // Function to fetch the data asynchronously
        const fetchData = async () => {
            try {
                // Querying the PromptHelperStyleImages and awaiting the result
                const images = await DataStore.query(PromptHelperStyleImages);

                // Filtering the images that are enabled
                const filteredImages = images.filter((image: PromptHelperStyleImages) => image.enabled);

                // Setting the filtered images to the state
                setImages(filteredImages);
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error("An error occurred while fetching the images:", error);
                }
            }
        };

        // Calling the fetchData function to fetch images when the component mounts
        fetchData();
    }, []);

    const handleDrawerClose = () => {
        props.handleDrawerClose();
    };

    const handleImageClick = (image: PromptHelperStyleImages) => {
        if (selectedImage?.id === image.id) {
            setSelectedImage(null);
            props.handleStyleImageChange([]);
        } else {
            setSelectedImage(image);
            props.handleStyleImageChange([image]);
        }
        handleDrawerClose();
    };

    return (
        <>
            <Drawer
                open={props.isOpen}
                anchor={isMobile ? "bottom" : "left"}
                onClose={handleDrawerClose}
                classes={{ paper: classes.drawerPaper }}
            >
                <Box sx={{ padding: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Styles
                        <Tooltip title="By clicking on a style, you can modify the generated image to be of that style.">
                            <IconButton size="small" sx={{ ml: 1 }}>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Box>
                <ImageList variant="quilted" cols={2} gap={16}>
                    {images ?
                        images.map((image: PromptHelperStyleImages) => (
                            <ImageListItem
                                key={image.id}
                                cols={1}
                                rows={10}
                                sx={{
                                    padding: 1,
                                    border: selectedImage?.id === image.id ? '2px solid blue' : 'none',
                                }}
                                onClick={() => handleImageClick(image)}
                            >
                                <img
                                    src={image.sourceUrl}
                                    alt={image.displayText}
                                    style={{ width: imageListItemDimension, height: imageListItemDimension }}
                                />
                                <ImageListItemBar title={image.displayText} />
                            </ImageListItem>
                        )) :
                        <Box sx={{ padding: 2 }}><Typography>Loading...</Typography></Box>
                    }
                </ImageList>
            </Drawer>
        </>
    );
};
