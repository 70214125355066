import React from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';

interface ClickableCardProps {
    body: string;
    onClick: (body: string) => void;
}

const ClickableCard = ({ body, onClick }: ClickableCardProps) => {
    return (
        <Card
            sx={{
                minHeight: '100px', // minimum height of the card
                height: '100%',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)', // You can customize the hover color here
                },
            }}>
            <CardActionArea onClick={() => onClick(body)} sx={{ height: '100%' }}>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {body}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ClickableCard;
