import { useCart, Item } from 'react-use-cart';
import { Box, Button, Chip, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { API } from 'aws-amplify';
import { encodeBase64 } from './util';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface FrameSizeDictionary {
    [frameSize: string]: string;
}

// TODO: fix this hack
const frameSizeDict: FrameSizeDictionary = {
    "FRA-INSTA-30X30": "12\"x12\" Mounted",
    "FRA-INSTA-40X40": "16\"x16\" Mounted"
    // Add more key-value pairs here for other frame sizes
};

// TODO: Move this to common place so both product view and cart can use it.
const productToDict = (product: Item) => {
    return {
        "id": product.id,
        "image_url": product.generatedImage.sourceUrl,
        "description": product.description,
        "price": product.price.toString(),
        "quantity": product.quantity,
        "frame_size": product.frameSize!,
        "frame_color": product.frameColor!,
    }
}

interface ProductDisplayProps {
    product: Item;
    size?: "small" | "medium" | "large";
}

type ProductDisplayState = {
    productPageRelativeUrl: string | null
}

const ProductDisplay: React.FC<ProductDisplayProps> = ({ product, size }) => {
    const [productDisplayState, setProductDisplayState] = React.useState<ProductDisplayState>({
        productPageRelativeUrl: null
    })
    const { updateItemQuantity, removeItem } = useCart();

    const handleAddClick = () => {
        updateItemQuantity(product.id, product.quantity! + 1);
    };

    const handleMinusClick = () => {
        updateItemQuantity(product.id, product.quantity! - 1);
    }

    const handleRemoveClick = () => {
        removeItem(product.id);
    };

    React.useEffect(() => {
        const createImageLink = async (productToDisplay: Item) => {
            // navigate to the product page
            const imageResult = await productToDisplay.generatedImage.imageResult;
            const options = { generatedImage: productToDisplay.generatedImage, description: imageResult?.prompt, price: productToDisplay.price };
            // Set the current state as a path id param
            // Allows each product page to have a unique url
            setProductDisplayState(p => ({
                ...p,
                productPageRelativeUrl: `/generate/product/${encodeBase64(JSON.stringify(options))}`
            }))
        }

        createImageLink(product)
    }, [product, product.id])

    return (
        <Paper elevation={3} sx={{ mb: 2, mr: 2, ml: 2, p: 2, display: 'flex', alignItems: 'center' }}>
            <a href={productDisplayState.productPageRelativeUrl ?? "/"} target="_blank" rel="noreferrer">
                <img src={product.generatedImage.sourceUrl} alt={product.description} style={{ width: 100, marginRight: 16 }} />
            </a>
            <Grid container spacing={1} justifyContent="space-between" alignItems="center" sx={{ flexGrow: 1 }}>
                <Grid item
                    xs={size && (size === "medium" || size === "large") ? false : 12}
                    md={size && (size === "small" || size === "large") ? false : 6}>
                    <Typography variant="h6">{product.description}</Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                        Frame size: {frameSizeDict[product.frameSize]}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                        Frame color: {product.frameColor}
                    </Typography>
                </Grid>
                <Grid item
                    xs={size && (size === "medium" || size === "large") ? false : 12}
                    md={size && (size === "small" || size === "large") ? false : 3}
                    sx={{ textAlign: 'right' }}>
                    <Typography variant="h6">${product.price.toFixed(2)}</Typography>
                </Grid>
                <Grid item
                    xs={size && (size === "medium" || size === "large") ? false : 12}
                    md={size && (size === "small" || size === "large") ? false : 3}
                    sx={{ textAlign: 'right' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button variant="outlined" size="small" onClick={handleMinusClick}>
                                -
                            </Button>
                            <Typography variant="subtitle1" component="span" sx={{ mx: 1 }}>
                                {product.quantity}
                            </Typography>
                            <Button variant="outlined" size="small" onClick={handleAddClick}>
                                +
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" size="small" onClick={handleRemoveClick} sx={{ marginLeft: 1 }}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

interface ShoppingCartPageProps {
    sx?: any;
    size?: "small" | "medium" | "large";
}

export const ShoppingCartPage = (props: ShoppingCartPageProps) => {
    const [enableCheckoutProgress, setEnableCheckoutProgress] = useState<boolean>(false);
    const { items, isEmpty, totalItems, cartTotal } = useCart()

    const handleOnClickCheckoutButton = async (checkoutItems: Item[]) => {
        setEnableCheckoutProgress(true)
        await checkout(checkoutItems)
        setEnableCheckoutProgress(false)
    };

    const checkout = async (checkoutItems: Item[]) => {
        const apiName = 'pythonapi';
        const path = '/checkout';
        const payload = {
            body: {
                "products": checkoutItems.map(productToDict),
                "success_redirect_url": `${window.location.origin}/checkout/success`,
                "cancel_redirect_url": window.location.href,
                "enable_test_mode": process.env.REACT_APP_ENABLE_STRIPE_TEST_MODE?.toLowerCase() === 'true'
            },
        };
        try {
            // Get user checkout link
            var response = await API.post(apiName, path, payload);
            // take response and navigate to the url
            // This is ugly but apparently window.open doesn't work for Safari or a few other browsers
            // https://stackoverflow.com/a/39387533
            // https://stackoverflow.com/a/11942772
            try { window.location.replace(response["checkout_session_url"]) }
            catch (e) { window.location = response["checkout_session_url"] }
        } catch (error: any) {
            toast.error("Oops something went wrong. Please try again")
        }
    }


    if (isEmpty) {
        return (
            <Box textAlign="center" sx={props.sx}>
                <Typography sx={{ m: 2 }} variant="h5">Your cart is empty</Typography>
            </Box >
        )
    }

    return (
        <Box sx={props.sx} m={2}>
            <ToastContainer
                position="top-center"
                newestOnTop={false}
                autoClose={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Grid container
                spacing={2}
                alignItems="flex-start"
                justifyContent="center">
                <Grid item
                    xs={props.size && (props.size === "medium" || props.size === "large") ? false : 12}
                    md={props.size && (props.size === "small" || props.size === "large") ? false : 8}>
                    {items.map((item) => (
                        <ProductDisplay key={item.id} product={item} size={props.size} />
                    ))}
                </Grid>
                <Grid item
                    xs={props.size && (props.size === "medium" || props.size === "large") ? false : 12}
                    md={props.size && (props.size === "small" || props.size === "large") ? false : 4} >
                    <Paper elevation={2} sx={{ p: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Subtotal ({totalItems} {totalItems === 1 ? 'item' : 'items'}):
                        </Typography>
                        <Typography variant="h6">
                            Total: ${cartTotal.toFixed(2)} <Chip variant="outlined" color="info" size="small" label="+ shipping" />
                        </Typography>
                        <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                            {enableCheckoutProgress
                                ? <CircularProgress />
                                : <Button variant="outlined" disabled={enableCheckoutProgress} onClick={() =>
                                    handleOnClickCheckoutButton(items)
                                }>Proceed to checkout</Button>}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box >
    )
};
