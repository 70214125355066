import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';

const SuccessPaymentPage: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          m: 2,
          minHeight: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" gutterBottom>
          Thank you for creating art with us!
        </Typography>
        <Typography variant="body1" gutterBottom>
          We'd like to let you know that WallAI Art has received your order, and is preparing it for production. You should receive a receipt email soon, along with another email containing the tracking number once your custom art has shipped.
        </Typography>
        <Button sx={{ m: 2 }} href="/generate" color="primary" variant='contained'>
          Continue
        </Button>
      </Box>
    </Container>
  );
};

export default SuccessPaymentPage;
