import { useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Authenticator, ThemeProvider, useAuthenticator, View } from '@aws-amplify/ui-react';
import { ReactComponent as LogoIcon } from "./logo.svg";
import '@aws-amplify/ui-react/styles.css';
import { Grid, Typography } from "@mui/material";
import { LoginFooter } from "./Footer";
import { DataStore, Hub } from "aws-amplify";

const useCustomComponents = () => {
    return {
        Header() {
            return (
                <Grid sx={{ mt: 2, mb: 2 }} container spacing={3} justifyContent={"center"}>
                    <Grid container item justifyContent={"center"} xs={12}>
                        <LogoIcon height={50} width={50} />
                    </Grid>
                    <Grid container item justifyContent={"center"} xs={12}>
                        <Typography variant="h5">
                            Sign in to WallAI Art
                        </Typography>
                    </Grid>
                </Grid>
            );
        },
        Footer() {
            return <LoginFooter />
        }
    }
}

const useLoginTheme = () => {
    return {
        name: 'Login Page Amplify Theme',
        tokens: {
            colors: {
                font: {
                    interactive: {
                        value: "#459cfa",
                    },
                },
                brand: {
                    primary: {
                        '10': "#e5f3fe",
                        '80': "#58abfb",
                        '90': "#c0e0fe",
                        '100': "#e5f3fe",
                    },
                },
            },
            components: {
                tabs: {
                    item: {
                        _focus: {
                            color: {
                                value: "#459cfa",
                            },
                        },
                        _hover: {
                            color: {
                                value: "#faa345",
                            },
                        },
                        _active: {
                            color: {
                                value: "#459cfa",
                            },
                        },
                    },
                },
            },
        },
    };
}

export function Login() {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const components = useCustomComponents();
    const theme = useLoginTheme();

    // If location state is index page then redirect to generate page
    // Else if the path exists direct to that path
    // default to /generate page
    let from = (
        location.state?.from?.pathname === "/"
            || location.state?.from?.pathname === ""
            ? '/generate' : location.state?.from?.pathname || '/generate'
    );
    useEffect(() => {
        if (route === 'authenticated') {
            navigate({
                pathname: from,
                // Check if the location state from has a search path since that's where we are going back to
                // TODO: this is kind of messy. Clean up. Consider determining search when we determine from
                search: location.state?.from?.search || searchParams.toString(),
            });
        }
    }, [route, navigate, from, location.state?.from?.search, searchParams]);

    Hub.listen('auth', async (data) => {
        if (data.payload.event === 'signOut') {
            // Clear local cache data when a user signs out
            // Useful if multiple users use the same device and/or development purposes
            await DataStore.clear();
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <View className="auth-wrapper">
                <Authenticator components={components}></Authenticator>
            </View>
        </ThemeProvider>
    );
}