import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ImagesearchRollerIcon from '@mui/icons-material/ImagesearchRoller';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import HistoryIcon from '@mui/icons-material/History';
import Divider from '@mui/material/Divider';
import { Link, useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { encodeBase64, getTextToImageResults } from './util';
import { Badge, Button, CircularProgress, Icon, ListItemIcon, styled } from '@mui/material';
import { ImageResult } from './models';
import { Auth, DataStore } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AmplifyUser } from '@aws-amplify/ui';
import { ArrowBack, ArrowBackIosNew, FavoriteBorderOutlined, ShoppingCartCheckoutOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import { isMobile, isAndroid } from 'react-device-detect'
import { InitImage } from './models';
import { useCart } from 'react-use-cart';

const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    color: "white",
    backgroundColor: "#faa345"
  }
});

const MenuBarShoppingCart = () => {
  const { totalItems } = useCart();
  const navigate = useNavigate();

  const handleOnShoppingCartClick = () => {
    navigate({ pathname: "/cart" });
  }

  return (
    <IconButton onClick={handleOnShoppingCartClick} size="large" color="inherit" aria-label="cart">
      <StyledBadge badgeContent={totalItems} >
        <ShoppingCartOutlined />
      </StyledBadge>
    </IconButton>
  )
}

const MenuBar = () => {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const [user, setUser] = React.useState<AmplifyUser>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = React.useState<boolean>(false);
  const [textToImageResultsHistory, setTextToImageResultsHistory] = React.useState<ImageResult[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [isLoadMoreProgress, setIsLoadMoreProgress] = React.useState<boolean>(false);
  const { emptyCart } = useCart();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const getCurrentUser = async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
    }

    if (route === 'authenticated') {
      getCurrentUser()
    }

  }, [route])

  const sortImageResults = (imageResults: ImageResult[]) => {
    return imageResults.sort((n1, n2) => {

      if (n1.createdAt && n2.createdAt) {
        if (n1.createdAt < n2.createdAt) {
          return 1;
        }

        if (n1.createdAt > n2.createdAt) {
          return -1;
        }

        return 0;
      } else if (n1.createdAt) {
        return -1
      } else {
        return 1
      }
    });
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    // close the menu
    handleMenuClose();
    // clear local cache
    await DataStore.clear();
    // clear local cart state
    emptyCart();
    // sign out
    signOut();
    // go to login page
    navigate({ pathname: "/login" });
  };

  const loadMoreHistory = async () => {
    // When we've opened the drawer, query for user's history results
    const textToImageResults = await getTextToImageResults(
      {
        page: page,
        limit: 1000,  // set limit high since datastore.query doesn't correctly sort
        onError: (error: Error) => console.log(error)
      });

    setPage(page + 1);
    // Manually sort the results since Datastore sort doesn't work
    const sortedResults = sortImageResults(textToImageResultsHistory.concat(textToImageResults ?? []));
    setTextToImageResultsHistory(sortedResults);
  }

  const handleLoadMoreButtonClick = async (event: React.KeyboardEvent | React.MouseEvent) => {
    setIsLoadMoreProgress(true);
    await loadMoreHistory()
    setIsLoadMoreProgress(false);
  }

  const handleMenuPromptHistoryClick = async (event: React.KeyboardEvent | React.MouseEvent) => {
    handleMenuClose();
    await toggleHistoryDrawer(true)(event)
  }

  const handleListButtonClick = async (textToImageResult: ImageResult) => {
    // Close toggle history drawer
    const initImages = await DataStore.query(InitImage, (i) => i.imageResult.id.eq(textToImageResult.id));
    if (initImages && initImages[0]) {
      const remixGeneratedImage = await initImages[0].generatedImage;
      const remixState = { remixImage: remixGeneratedImage, remixImageResultID: textToImageResult.id }
      setIsHistoryDrawerOpen(false)
      navigate({
        pathname: `/generate/remix/${encodeBase64(JSON.stringify(remixState))}`,
      });
    } else {
      setIsHistoryDrawerOpen(false)
      navigate({
        pathname: "/generate",
        search: createSearchParams({
          state: encodeBase64(JSON.stringify(textToImageResult))
        }).toString()
      });
    }
  }

  const handleFavoritesMenuButtonClick = () => {
    navigate({ pathname: "/generate/favorites" });
  }

  const handleGeneratePageMenuButtonClick = () => {
    navigate({ pathname: "/generate" });
  }

  const handleExploreMenuButtonClick = () => {
    navigate({ pathname: "/explore" });
  }

  const handleBackArrowClick = () => {
    navigate(-1)
  }

  const toggleHistoryDrawer =
    (open: boolean) =>
      async (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setIsHistoryDrawerOpen(open);
        // Only load more history when none has been retrieved
        // OR when the load more history button is clicked
        // Simply opening the prompty history drawer should not load more history.
        if (open && page === 0) {
          setIsLoading(true)
          await loadMoreHistory()
          setIsLoading(false)
        }
      };

  const _uniqueImageResults = (imageResults: ImageResult[]) => {
    return Array.from(new Set(imageResults.map((r) => r.id))).map((id) => {
      return imageResults.find((r) => r.id === id);
    })
  }

  // Determines if product page view is being displayed.
  // This is useful for determining upwards navigation.
  // We should probably use some framework to handle this complex navigation
  // automatically but for now we do the simplest and non-scalable thing.
  const _isNestedPage = () => {
    return (
      location.pathname.includes("generate/product") ||
      location.pathname.includes("generate/remix")
    )
  }


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {isMobile && _isNestedPage()
            ? <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleBackArrowClick}
            >
              {isAndroid ? <ArrowBack /> : <ArrowBackIosNew />}
            </IconButton>
            : <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleHistoryDrawer(true)}
            >
              <ImagesearchRollerIcon />
            </IconButton>
          }
          <React.Fragment key={"left"}>
            <SwipeableDrawer
              anchor={"left"}
              open={isHistoryDrawerOpen}
              onClose={toggleHistoryDrawer(false)}
              onOpen={toggleHistoryDrawer(true)}
            >
              {isLoading
                ? <CircularProgress sx={{ m: 3 }} />
                :
                <div>
                  <Toolbar>
                    <HistoryIcon sx={{ m: 1 }} />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Typography sx={{ m: 1 }} variant="h6" color="inherit" component="div">
                      Prompt History
                    </Typography>
                  </Toolbar>
                  <Divider />
                  <List sx={{ maxWidth: 250 }}>
                    {_uniqueImageResults(textToImageResultsHistory).map((r, index) => (
                      <ListItem onClick={() => handleListButtonClick(r!)} key={r!.id} disablePadding>
                        <ListItemButton>
                          <ListItemText primary={r!.prompt} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                  {isLoadMoreProgress
                    ? <CircularProgress sx={{ m: 3 }} />
                    : <Box textAlign='center'>
                      <Button variant="text" onClick={handleLoadMoreButtonClick}>
                        Load More
                      </Button>
                    </Box>
                  }
                </div>}
            </SwipeableDrawer>
          </React.Fragment>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
              Wallai Art
            </Link>
          </Typography>
          <MenuBarShoppingCart />
          {route === 'authenticated' && user != null ?
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem disabled>{user!.attributes!.email}</MenuItem>
                <MenuItem onClick={handleGeneratePageMenuButtonClick}>Generate</MenuItem>
                <MenuItem onClick={handleMenuPromptHistoryClick}>Prompt History</MenuItem>
                <MenuItem onClick={handleFavoritesMenuButtonClick}>
                  <ListItemIcon>
                    <Icon>
                      <FavoriteBorderOutlined sx={{ color: "#222" }} />
                    </Icon>
                  </ListItemIcon>
                  Favorites
                </MenuItem>
                <MenuItem onClick={handleExploreMenuButtonClick}>Explore</MenuItem>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </div>
            : <div></div>}
        </Toolbar>
      </AppBar>
    </Box >
  );
}

export default MenuBar;
