import React from "react";
import { Amplify, AuthModeStrategyType } from "aws-amplify";
import {
  Authenticator,
} from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { FavoritesAuthenticatedRoute, ProductAuthenticatedRoute, RemixAuthenticatedRoute, ShoppingCartAuthenticatedRoute, SuccessPaymentAuthenticatedRoute, TexttoImageAuthenticatedRoute } from "./AppAuthRoutes";
import "@aws-amplify/ui-react/styles.css";
import { Login } from "./Login";
import LandingPage from "./LandingPage";
import { ThemeProvider } from '@mui/styles'
import { createTheme } from '@mui/material'
import { CartProvider } from "react-use-cart";
import SuccessPaymentPage from "./SuccessPaymentPage";
import { ExplorePage } from "./ExplorePage";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

const [
  productionRedirectSignIn,
  localRedirectSignIn,
] = awsExports.oauth.redirectSignIn.split(",");

const [
  productionRedirectSignOut,
  localRedirectSignOut,
] = awsExports.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    domain: "auth.wallai.art",
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  },
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
}

Amplify.configure(updatedAwsConfig);

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/generate" element={<TexttoImageAuthenticatedRoute />} />
        {
          // This is a bit strange but we explicitly add the trailing slash route
          // on the deployed server this is causing a 404 to be returned.
        }
        <Route path="/generate/" element={<TexttoImageAuthenticatedRoute />} />
        <Route path="/generate/product/:productState" element={<ProductAuthenticatedRoute />} />
        <Route path="/generate/remix/:remixState" element={<RemixAuthenticatedRoute />} />
        <Route path="/generate/favorites" element={<FavoritesAuthenticatedRoute />} />
        <Route path="/cart" element={<ShoppingCartAuthenticatedRoute />} />
        <Route path="/checkout/success" element={<SuccessPaymentAuthenticatedRoute />} />
        <Route path="/explore" element={<ExplorePage />} />
        <Route path="/" element={<LandingPage />} />
      </Routes >
    </Router >
  )
}

const App = () => {
  const theme = createTheme()

  return (
    <ThemeProvider theme={theme} >
      <Authenticator.Provider>
        <CartProvider>
          <AppRoutes />
        </CartProvider>
      </Authenticator.Provider>
    </ThemeProvider>
  );
};

export default App;
