import React, { useEffect, useRef, useState } from 'react';
import { PromptActionButton } from './PromptActionButton';
import { PromptHelperToolsDrawer } from './PromptHelperToolsDrawer';
import { PromptHelperStyleImages } from '../models';

interface StyleToolsWrapperProps {
    isOpen: boolean;
    handleStyleImageChange: (styles: PromptHelperStyleImages[]) => void;
    disabled?: boolean;
}

export const StyleToolsWrapper = (props: StyleToolsWrapperProps) => {
    const [isDrawerOpen, setDrawerOpen] = useState(props.isOpen);
    const toggleDrawerOpen = () => setDrawerOpen(!isDrawerOpen);

    // Use a ref to track if the component is mounted
    const isMounted = useRef(true);

    useEffect(() => {
        // Set isMounted to true when the component mounts
        isMounted.current = true;

        // Define a cleanup function that will run when the component unmounts
        return () => {
            isMounted.current = false;
        };
    }, []); // Empty dependency array ensures this runs on mount and unmount

    useEffect(() => {
        // Check if the component is mounted before updating state
        if (isMounted.current) {
            setDrawerOpen(props.isOpen);
        }
    }, [props.isOpen]);

    return (
        <div>
            <PromptActionButton
                buttonTooltipText="Click to explore styles"
                buttonText="Styles"
                isOpen={isDrawerOpen}
                toggleOpen={toggleDrawerOpen}
                disabled={props.disabled}
            />
            <PromptHelperToolsDrawer
                isOpen={isDrawerOpen}
                handleDrawerClose={toggleDrawerOpen}
                handleStyleImageChange={props.handleStyleImageChange}
            />
        </div>
    );
};
