import React from 'react';
import { makeStyles, createStyles } from '@mui/styles'
import { Container, Grid, Typography, Link, Theme, SvgIcon } from '@mui/material';
import { ReactComponent as LogoIcon } from "./logo.svg";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(6, 0),
        },
        text: {
            color: '#656d76',
            marginBottom: theme.spacing(2),
        },
        link: {
            fontSize: '12px'
        },
    })
);

export const Footer: React.FC = () => {
    const classes = useStyles();

    return (
        <Container sx={{ mt: 5, mb: 5 }}>
            <hr />
            <Grid container justifyContent={"center"} spacing={1}>
                <Grid container justifyContent={"center"} item xs={12} md={3}>
                    {
                        // mr provides some margin-right between the icon and the wallai text
                    }
                    <SvgIcon sx={{ mr: 2 }} viewBox="0 0 20 20">
                        <LogoIcon height={20} width={20} />
                    </SvgIcon>
                    <Typography variant="subtitle1" className={classes.text}>
                        © 2024 Wall AI Art
                    </Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                    <Link href="http://legal.wallai.art/terms.html" target="_blank" className={classes.link}>
                        Terms
                    </Link>
                </Grid>
                <Grid item xs={2} md={1}>
                    <Link href="http://legal.wallai.art/privacy.html" target="_blank" className={classes.link}>
                        Privacy
                    </Link>
                </Grid>
                <Grid item xs={4} md={2}>
                    <Link href="http://legal.wallai.art/refundAndReturns.html" target="_blank" className={classes.link}>
                        Refund / Returns
                    </Link>
                </Grid>
            </Grid>
        </Container >
    );
};

export const LoginFooter: React.FC = () => {
    const classes = useStyles();

    return (
        <Container sx={{ mt: 5, mb: 5 }}>
            <hr />
            <Grid container justifyContent={"center"} spacing={1}>
                <Grid item xs={2} md={2}>
                    <Link href="http://legal.wallai.art/terms.html" target="_blank" className={classes.link}>
                        Terms
                    </Link>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Link href="http://legal.wallai.art/privacy.html" target="_blank" className={classes.link}>
                        Privacy
                    </Link>
                </Grid>
                <Grid item xs={5} md={3}>
                    <Link href="http://legal.wallai.art/refundAndReturns.html" target="_blank" className={classes.link}>
                        Refund / Returns
                    </Link>
                </Grid>
            </Grid>
        </Container >
    );
};
