/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://ulayikrr3fg43inl7mrzriwoem.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-xo6wakt655bn7plwiegtc3ma2m",
    "aws_cloud_logic_custom": [
        {
            "name": "pythonapi",
            "endpoint": "https://uxj50w0ea0.execute-api.us-east-1.amazonaws.com/devq",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:6b060645-9107-48c2-a2df-6ce97cfd2b9b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_DqKHnuO5J",
    "aws_user_pools_web_client_id": "6qv55g7bggg6d06m8u4nu1a4qp",
    "oauth": {
        "domain": "wallaiart-devq.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.wallai.art/,http://localhost:3000/",
        "redirectSignOut": "https://www.wallai.art/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "wallai-prod-images",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
