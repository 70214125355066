import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { API } from 'aws-amplify';
import TextToImageTextField from './TextToImageTextField';
import ButtonBaseImageListView, { GeneratedImageDisplayButton } from './ButtonBase';
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createGeneratedImageFromPublicImage, decodeBase64, encodeBase64, getTextToImageResult, saveTextToImageResult } from './util'
import { GeneratedImage, ImageResult, LazyGeneratedImage, LazyPublicImage, PublicImage } from "./models";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { LinearIndeterminate } from "./components/LinearIndeterminate";

export const TextToImageGeneratePage = () => {
  const [textToImageResult, setTextToImageResult] = useState<ImageResult | null>();
  const [generatedImages, setGeneratedImages] = useState<GeneratedImage[]>();
  const [hasloaded, setHasLoaded] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const stateParam = searchParams.get('state')!

  const fetchGeneratedImages = async (prompt: string) => {
    if (prompt && /\S/.test(prompt)) {
      const apiName = 'pythonapi';
      const path = '/textToImage';
      const payload = {
        body: { "prompt": prompt },
      };
      try {
        var response = await API.post(apiName, path, payload);
        const imageResult = await saveTextToImageResult({
          prompt: response["prompt"],
          imageUrls: response["imageUrls"],
          aiGeneratedAlternativePrompts: response["alternativePrompts"],
        })
        // Set the query params as the response from the server.
        // Allows a user to navigate back to a previous generated image page
        setTextToImageResult(imageResult)
        navigate({
          pathname: "/generate",
          search: createSearchParams({
            state: encodeBase64(JSON.stringify(imageResult))
          }).toString()
        });
      } catch (error: any) {
        if (error.response && error.response.data) {
          toast.error(error.response.data)
        } else {
          toast.error("Oops something went wrong. Please try again")
        }
      }
    } else {
      toast.error("Prompt is empty. Please write a non-empty prompt.");
    }
  }

  useEffect(() => {
    const navigateToQueryResponse = async (stateParam: string) => {
      if (stateParam) {
        const props = JSON.parse(decodeBase64(stateParam)) as ImageResult;
        const imageResult = await getTextToImageResult(props.id)
        const generatedImagesFromResult = await imageResult?.generatedImages.toArray();
        setGeneratedImages(generatedImagesFromResult)
        setTextToImageResult(imageResult)
      } else {
        setGeneratedImages([])
        setTextToImageResult(null)
      }
      setHasLoaded(true)
    }

    navigateToQueryResponse(stateParam)
  }, [stateParam]);

  return (
    (!hasloaded)
      ? <LinearIndeterminate /> :
      <Box m={2}>
        <ToastContainer
          position="top-center"
          newestOnTop={false}
          autoClose={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={6}>
            <Stack spacing={2} direction="column">
              <TextToImageTextField
                prompt={textToImageResult ? textToImageResult.prompt : ""}
                alternativePrompts={textToImageResult?.aiGeneratedAlternativePrompts ? (textToImageResult.aiGeneratedAlternativePrompts as string[]) : []}
                onGenerate={fetchGeneratedImages} />
              {textToImageResult
                ? <ButtonBaseImageListView generatedImages={generatedImages ?? []} price={34.99} />
                : <div></div>}
            </Stack>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
      </Box>
  )
}

type RemixState = {
  remixImage: GeneratedImage,
  remixImageResultID?: (string | null)
  prompt: string
}

type PublicImageState = {
  publicImage: PublicImage | null
};

const TextToImageRemixPage = (props: RemixState) => {
  // TODO: refactor to have a single shared state with multiple fields
  const [textToImageResult, setTextToImageResult] = useState<ImageResult | null>();
  const [generatedImages, setGeneratedImages] = useState<GeneratedImage[]>();
  const [remixImage, setRemixImage] = useState<GeneratedImage>(props.remixImage);
  const navigate = useNavigate();

  const fetchGeneratedRemixImages = async (prompt: string) => {
    if (prompt && /\S/.test(prompt)) {
      const apiName = 'pythonapi';
      const path = '/textToImage';
      const payload = {
        body: {
          "prompt": prompt,
          "init_image_url": remixImage?.sourceUrl
        },
      };
      try {
        var response = await API.post(apiName, path, payload);
        const imageResult = await saveTextToImageResult({
          prompt: response["prompt"],
          imageUrls: response["imageUrls"],
          initImage: remixImage,
          aiGeneratedAlternativePrompts: response["alternativePrompts"],
        })

        // Set the query params as the response from the server.
        // Allows a user to navigate back to a previous generated image page
        setTextToImageResult(imageResult)
        const state = { remixImage: remixImage, remixImageResultID: imageResult.id }
        navigate({
          pathname: `/generate/remix/${encodeBase64(JSON.stringify(state))}`,
        });
      } catch (error: any) {
        if (error.response && error.response.data) {
          toast.error(error.response.data)
        } else {
          toast.error("Oops something went wrong. Please try again")
        }
      }
    } else {
      toast.error("Prompt is empty. Please write a non-empty prompt.");
    }
  }

  useEffect(() => {
    const updateState = async (remixImg: LazyGeneratedImage, remixImageResultID: string | null | undefined) => {
      if (remixImageResultID) {
        const imageResult = await getTextToImageResult(remixImageResultID)
        const generatedImagesFromResult = await imageResult?.generatedImages.toArray();
        setRemixImage(remixImg)
        setGeneratedImages(generatedImagesFromResult)
        setTextToImageResult(imageResult)
      } else {
        setRemixImage(remixImg)
        setTextToImageResult(null)
        setGeneratedImages([])
      }
    }

    updateState(props.remixImage, props.remixImageResultID)
  }, [props.remixImage, props.remixImageResultID]);

  return (
    <Box m={2}>
      <ToastContainer
        position="top-center"
        newestOnTop={false}
        autoClose={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="center"
      >
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Stack spacing={2} direction="column">
            <Grid container justifyContent={"center"}>
              <GeneratedImageDisplayButton generatedImage={remixImage!} price={34.99} />
            </Grid>
            <TextToImageTextField
              prompt={textToImageResult ? textToImageResult.prompt : ""}
              onGenerate={fetchGeneratedRemixImages}
              generateButtonText="Remix"
              placeholder="Describe how you want the image to change"
              disablePromptSuggestions={true} // Don't show prompt suggestions/examples for remix
              alternativePrompts={textToImageResult?.aiGeneratedAlternativePrompts ? (textToImageResult.aiGeneratedAlternativePrompts as string[]) : []}
            />
            {textToImageResult
              ? <ButtonBaseImageListView generatedImages={generatedImages ?? []} price={34.99} />
              : <div></div>}
          </Stack>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
    </Box>
  )
}

const TextToImageRemixFromPublicImagePage = (props: PublicImageState) => {
  const [remixState, setRemixState] = useState<RemixState | null>(null);

  useEffect(() => {
    const generateRemixImage = async (publicImage: LazyPublicImage | null) => {
      if (publicImage) {
        const { imageResult, generatedImage } = await createGeneratedImageFromPublicImage(publicImage);
        setRemixState(
          {
            remixImage: generatedImage,
            remixImageResultID: imageResult.id,
            prompt: imageResult.prompt
          });
      }
    }
    generateRemixImage(props.publicImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publicImage?.id]);

  return (remixState ? <TextToImageRemixPage {...remixState} /> : <div></div>)
}

export const TextToImageRemixPageWrapper = () => {
  const params = useParams();
  const state = JSON.parse(decodeBase64(params.remixState!));

  if (state.publicImage) {
    const publicImageState: PublicImageState = state;
    return <TextToImageRemixFromPublicImagePage {...publicImageState} />;
  } else {
    const remixState: RemixState = state;
    return <TextToImageRemixPage {...remixState} />;
  }
};
