import React, { useState } from 'react';
import { Box, Button, Tooltip } from '@mui/material';

interface PromptActionButtonProps {
    children?: React.ReactNode;
    buttonTooltipText: string;
    buttonText: string;
    isOpen: boolean;
    toggleOpen: () => void;
    disabled?: boolean;
}

/**
 * A button component designed to trigger prompt-related actions.
 * Usage:
 * ```jsx
 * const [isOpen, setIsOpen] = useState(false);
 * const toggleOpen = () => setIsOpen(!isOpen);
 *
 * <PromptActionButton buttonTooltipText="Click to explore alternatives" buttonText="Explore" isOpen={isOpen} toggleOpen={toggleOpen}>
 *   <Collapse in={isOpen} ...>
 *     ...
 *   </Collapse>
 * </PromptActionButton>
 * ```
 */
export const PromptActionButton = (props: PromptActionButtonProps) => {
    return (
        <Box m={1}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" mb={2}>
                <Tooltip title={props.buttonTooltipText}>
                    <span>
                        <Button disabled={props.disabled} variant="text" onClick={props.toggleOpen}>
                            {props.buttonText}
                        </Button>
                    </span>
                </Tooltip>
            </Box>
            {props.isOpen && props.children}
        </Box>
    );
};

