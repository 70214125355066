// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Category, ImageResult, GeneratedImage, InitImage, PublicImage, PromptHelperStyleImages, ProdigiProduct } = initSchema(schema);

export {
  Category,
  ImageResult,
  GeneratedImage,
  InitImage,
  PublicImage,
  PromptHelperStyleImages,
  ProdigiProduct
};