import React, { useEffect } from 'react';
import Chip from '@mui/material/Chip';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { PromptHelperStyleImages } from '../models';
import { Avatar } from '@mui/material';

interface ChipData {
    style: PromptHelperStyleImages
    color: 'default' | 'primary' | 'secondary';
    type?: string; // Optional type to represent different kinds of chips.
}

interface ChipInputTextFieldProps extends Omit<TextFieldProps, 'value' | 'placeholder'> {
    value?: string;
    placeholder?: string;
    chipData: ChipData[];
    onChipDelete?: (style: PromptHelperStyleImages) => void; // Optional deletion handler.
    onTextChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Optional text change handler.
    onKeyDown?: (event: React.KeyboardEvent) => Promise<void>;
}

export const ChipInputTextField: React.FC<ChipInputTextFieldProps> = (props: ChipInputTextFieldProps) => {
    const [text, setText] = React.useState<string>(props.value ?? '');

    useEffect(() => {
        setText(props.value ?? '');
    }, [props.value]);

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
        props.onTextChange?.(event);
    };

    return (
        <Grid container direction="column" alignItems="center" spacing={1}>
            <Grid item xs={12} container alignItems="center">
                <TextField
                    disabled={props.disabled}
                    sx={{ ml: 1, flex: 1 }}
                    multiline
                    variant="standard"
                    placeholder={props.placeholder}
                    value={text}
                    onChange={handleTextChange}
                    onKeyDown={props.onKeyDown}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        ...props.InputProps,
                        disableUnderline: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="flex-start">
                {props.chipData.map(({ style, color }) => (
                    <Chip
                        avatar={<Avatar alt={style.displayText} src={style.sourceUrl} />}
                        key={style.id}
                        label={style.displayText}
                        color={color}
                        onDelete={props.onChipDelete ? () => props.onChipDelete!(style) : undefined}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

