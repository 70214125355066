import React, { useState } from "react";
import { useEffect } from "react";
import { AppBar, Button, IconButton, Grid, Paper, Slide, Toolbar, Typography, useMediaQuery, Theme, Drawer, List, Menu, MenuItem } from "@mui/material";
import { ReactComponent as LogoIcon } from "./logo.svg";
import { SvgIcon } from '@mui/material';
import { Box, Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MenuIcon from '@mui/icons-material/Menu';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import HeroIllustrationImg1 from './landing-page-assets/landingPageHeroIllustration-1.png';
import HeroIllustrationImg2 from './landing-page-assets/landingPageHeroIllustration-2.png';
import HeroIllustrationImg3 from './landing-page-assets/landingPageHeroIllustration-3.png';
import HeroIllustrationImg4 from './landing-page-assets/landingPageHeroIllustration-4.png';
import { Footer } from "./Footer";
import Carousel from "react-material-ui-carousel";
import { createStyles, makeStyles } from "@mui/styles";
import { isMobile } from 'react-device-detect';

const IMAGES = [
    {
        prompt: "Robot and small cute cartoon characters, vibrant colors, as digital art",
        imgSource: HeroIllustrationImg1
    },
    {
        prompt: "Create a digital art piece that highlights the close-up portrait of a friendly and cute baby animal's face with pink hues and a flower, suitable for exhibiting in a children's room. The animal should be a koala bear, panda bear, or giraffe.",
        imgSource: HeroIllustrationImg2
    },
    {
        prompt: "Produce a digital art image that features break dancing executed in a style that blends graffiti art with Japanese influences.",
        imgSource: HeroIllustrationImg3
    },
    {
        prompt: "Generate a digital art piece portraying a stunning portrait of a woman near a flower or flowers that evokes strong emotions.",
        imgSource: HeroIllustrationImg4
    }
]

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        carouselGrid: {
            [theme.breakpoints.down("md")]: {
                height: "450px"
            },

            [theme.breakpoints.up("md")]: {
                height: "550px"
            },
        }
    })
);

const HeroLandingContainer = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleGetStartedButtonClick = async () => {
        navigate({
            pathname: "/login",
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                    <Box sx={{ m: 3 }}>
                        <Box sx={{ m: 2 }}>
                            <Typography variant="h3">
                                <Typography variant="inherit" style={{ color: "#2690e6" }}>Buy your art</Typography> like never before.
                            </Typography>
                        </Box>
                        <Box sx={{ m: 2 }}>
                            <Typography variant="h6" style={{ color: "#6F8394" }}>
                                Turn your words, thoughts, and ideas into physical pieces of art - your walls will never be the same!
                            </Typography>
                        </Box>
                        <Box>
                            <Button sx={{ m: 2 }} onClick={handleGetStartedButtonClick} variant="contained" style={{ backgroundColor: "#2690e6" }}>Get Started</Button>
                        </Box>
                    </Box>
                </Slide>
            </Grid>
            {
                // linear-gradient background for the blue color
            }
            <Grid item xs={12} md={6} className={classes.carouselGrid} style={{ background: "linear-gradient(to left, #2690e6 0% 20%, #2690e6 20% 40%, #2690e6 40% 60%, #2690e6 60% 80%, #FFFFFF 80% 100%)" }}>
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <Container className={classes.carouselGrid}>
                        <Carousel className={classes.carouselGrid}>
                            {IMAGES.map((image) => {
                                return (
                                    <Box key={1} sx={{ height: "100%", m: 1 }}>
                                        <Box sx={{ height: "100%", m: 2 }} >
                                            {
                                                // Modify size of svg image
                                            }
                                            <img alt={image.prompt} style={{ width: '100%' }} src={image.imgSource} />
                                        </Box>
                                        <Box sx={{ m: 1 }} style={{ background: "#FFFFFF" }}>
                                            <Typography variant="subtitle1" style={{ color: "#2690e6" }}>User prompt: </Typography>
                                            <Typography variant="subtitle2" style={{ color: "#6F8394" }}>{image.prompt}</Typography>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Carousel>
                    </Container>
                </Slide>
            </Grid>
        </Grid >
    )
}

type FeatureProps = {
    icon: JSX.Element
    title: string;
    description: string;
}

type TransitionIconProps = {
    isSmallScreen: boolean
}

const FeaturesContainer = () => {
    const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
    const TransitionIcon = ({ isSmallScreen }: TransitionIconProps) => {
        return (
            isSmallScreen ?
                <ArrowDownwardOutlinedIcon style={{ color: "#FD5279", fontSize: '42px' }} />
                : <ForwardOutlinedIcon style={{ color: "#FD5279", fontSize: '42px' }} />
        )
    }

    const Feature = ({ icon, title, description }: FeatureProps) => {
        return (
            <Paper sx={{ m: 2, height: isSmallScreen ? '250px' : '275px' }} elevation={4}>
                <Grid container spacing={2} alignItems="center" direction="column">
                    <Grid item>
                        {icon}
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" color="text.primary" gutterBottom>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ m: 1 }}>
                        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                            {description}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    return (
        <Box sx={{ mt: 4 }} style={{ background: "#fcfcfc" }}>
            <Typography variant="h2" style={{ color: "#2690e6", display: "flex", justifyContent: "center" }}>The Process</Typography>
            <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={1}>
                <Grid item xs={12} md={3}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={11}>
                            <Feature icon={<ImagesearchRollerIcon style={{ color: "#2690e6" }} />} title="Generate Art" description="Describe in words the art you want to see and have it come to life before your eyes. Redescribe and remix to your heart's content." />
                        </Grid>
                        <Grid item xs={12} md={1} style={{ textAlign: "center" }}>
                            <TransitionIcon isSmallScreen={isSmallScreen} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={11}>
                            <Feature icon={<FilterFramesIcon style={{ color: "#2690e6" }} />} title="Frame & Style" description="Choose your frame, color & style for your art to be displayed." />
                        </Grid>
                        <Grid item xs={12} md={1} style={{ textAlign: "center" }}>
                            <TransitionIcon isSmallScreen={isSmallScreen} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={11}>
                            <Feature icon={<LocalShippingIcon style={{ color: "#2690e6" }} />} title="Ship" description="Buy your art and have it directly shipped to your doorstep." />
                        </Grid>
                        <Grid item xs={12} md={1} style={{ textAlign: "center" }}>
                            <TransitionIcon isSmallScreen={isSmallScreen} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={11}>
                            <Feature icon={<VrpanoIcon style={{ color: "#2690e6" }} />} title="Display the Art" description="Fill the empty walls in your home with your unique art." />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </Box>
    )
}

export const LandingAppBar = () => {
    const navigate = useNavigate();

    const handleExploreButtonClick = async () => {
        navigate({
            pathname: "/explore",
        });
    }

    const handleSignInButtonClick = async () => {
        navigate({
            pathname: "/login",
        });
    }

    const DesktopAppBar = () => {
        return (
            <AppBar sx={{ m: 1 }} position="sticky" style={{ background: 'white', boxShadow: 'none' }}>
                <Container maxWidth="lg">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <SvgIcon viewBox="0 0 20 20">
                                <LogoIcon height={20} width={20} />
                            </SvgIcon>
                        </IconButton>
                        <Typography variant="h5" component="div" style={{ color: "#2690e6" }} sx={{ flexGrow: 1 }}>
                            WallAI Art
                        </Typography>
                        <Button onClick={handleExploreButtonClick} variant="outlined" style={{ color: "#2690e6", borderColor: "#2690e6", marginRight: "10px" }}>Explore</Button>
                        <Button onClick={handleSignInButtonClick} variant="contained" style={{ backgroundColor: "#2690e6" }}>Sign In</Button>
                    </Toolbar>
                </Container>
            </AppBar>
        )
    }

    const MobileAppBar = () => {
        const [isDrawerOpen, setIsDrawerOpen] = useState(false);
        return (
            <AppBar sx={{ m: 1 }} position="sticky" style={{ background: 'white', boxShadow: 'none' }}>
                <Container maxWidth="lg">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <SvgIcon viewBox="0 0 20 20">
                                <LogoIcon height={20} width={20} />
                            </SvgIcon>
                        </IconButton>
                        <Typography variant="h5" component="div" style={{ color: "#2690e6" }} sx={{ flexGrow: 1 }}>
                            WallAI Art
                        </Typography>
                        <IconButton
                            edge="start"
                            style={{ color: "#2690e6" }}
                            aria-label="menu"
                            onClick={() => setIsDrawerOpen(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={null}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={isDrawerOpen}
                            onClose={() => setIsDrawerOpen(false)}
                        >
                            <MenuItem onClick={handleSignInButtonClick}>Sign In</MenuItem>
                            <MenuItem onClick={handleExploreButtonClick}>Explore</MenuItem>
                        </Menu>
                    </Toolbar>
                </Container>
            </AppBar >
        )
    }

    return (
        isMobile
            ? <MobileAppBar />
            : <DesktopAppBar />
    )
}


const LandingPage = () => {
    const { route } = useAuthenticator((context) => [context.route]);
    const navigate = useNavigate();


    useEffect(() => {
        // If user is authenticated then redirect them to the generate page
        if (route === 'authenticated') {
            navigate({
                pathname: "/generate",
            });
        }
    }, [route, navigate]);


    return (
        <div>
            {
                // Header with svg logo icon on left hand side
                // clear menu bar
            }
            <LandingAppBar />
            {
                // Hero landing area
            }
            <Container maxWidth="lg">
                <HeroLandingContainer />
            </Container>
            {
                // Feature + process guide
            }
            <Container maxWidth="lg">
                <FeaturesContainer />
            </Container>
            {
                // TODO: Gif area showcasing results
            }
            <Footer />
        </div >
    );

}

export default LandingPage;
