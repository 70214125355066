import * as React from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Carousel from 'react-material-ui-carousel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate, useParams } from "react-router-dom";
import styles from './PhotoFrame.module.css';
import Alert from '@mui/material/Alert';
import { API, DataStore } from 'aws-amplify';
import { decodeBase64, encodeBase64 } from './util'
import { Chip, IconButton, Link, SwipeableDrawer, Tooltip } from '@mui/material';
import { AutoFixHigh, Favorite, FavoriteBorderOutlined } from '@mui/icons-material';
import { GeneratedImage } from './models';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { useCart } from "react-use-cart";
import { MD5 } from 'crypto-js';
import mergeImages from 'merge-images';
import Image from "image-js";
import { ShoppingCartPage } from './ShoppingCart';
import { isMobile } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Product = {
    generatedImage: GeneratedImage;
    description: string;
    price: number;
    frameSize?: string;
    frameColor?: string;
}

const productToPrice = (product: Product) => {
    switch (product.frameSize) {
        case "FRA-INSTA-30X30":
            return 34.99;
        case "FRA-INSTA-40X40":
            return 44.99;
        default:
            return 34.99;
    }
}

function createProductIdentifier(product: Product): string {
    const delimiter = '-';
    const concatenated = `${product.generatedImage.id}${delimiter}${product.description}${delimiter}${product.frameSize}${delimiter}${product.frameColor}${delimiter}${product.price}`;
    return MD5(concatenated).toString();
}

const productToDict = (product: Product) => {
    return {
        "id": createProductIdentifier(product),
        "image_url": product.generatedImage.sourceUrl,
        "description": product.description,
        "price": productToPrice(product).toString(),
        "quantity": 1, // currently always single quantity with buy now feature
        "frame_size": product.frameSize!,
        "frame_color": product.frameColor!
    }
}

type ProductComponentViewProps = Product

type ProductDetailsComponentProps = {
    product: Product,
    onChange: any
}

const ProductDetailsComponent = ({ product, onChange }: ProductDetailsComponentProps) => {
    const [enableAlert, setEnableAlert] = useState<boolean>(false);
    const [enableCheckoutProgress, setEnableCheckoutProgress] = useState<boolean>(false);
    const [enableShoppingCartDrawer, setEnableShoppingCartDrawer] = useState<boolean>(false);
    const [productState, setProductState] = useState<Product>(
        { ...product, frameSize: product.frameSize ?? 'FRA-INSTA-30X30', frameColor: product.frameColor ?? "Black" });
    const { addItem } = useCart();
    const navigate = useNavigate();

    const getLatestGeneratedImage = async (latestProductState: Product, generatedImage: GeneratedImage) => {
        // We have to get the generatedImage from the dataStore since the generatedImage object
        // that is initailly stored in the productState isn't considered a valid model object since
        // we use json to stringify the object.
        // This loses some data that DataStore uses to validate the object's model. So wif we try to update
        // the generateImage's fields via a copyOf call, we will get an exception that will be thrown.
        const latestGeneratedImage = await DataStore.query(GeneratedImage, generatedImage.id)
        // Update the productState with the latest value
        setProductState({ ...latestProductState, generatedImage: latestGeneratedImage ? latestGeneratedImage : generatedImage })
    }

    React.useEffect(() => {
        const latestProduct = {
            ...product,
            frameSize: productState.frameSize ?? 'FRA-INSTA-30X30',
            frameColor: productState.frameColor ?? "Black"
        };
        getLatestGeneratedImage(latestProduct, product.generatedImage)
        setProductState(latestProduct)
        // ignore this warning because props.generatedImage.id is what we use to determine if product & generatedImage has changed
    }, [product.generatedImage.id]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleFrameSizeChange = (event: SelectChangeEvent) => {
        const frameSize = event.target.value;
        const updatedProductState = { ...productState, frameSize: frameSize }
        setProductState(updatedProductState);
        onChange(updatedProductState);
    };

    const handleFrameColorChange = (event: SelectChangeEvent) => {
        const frameColor = event.target.value;
        const updatedProductState = { ...productState, frameColor: frameColor }
        setProductState(updatedProductState)
        onChange(updatedProductState)
    };

    const handleOnClickBuyButton = async (toPurchaseProduct: Product) => {
        console.log(`REACT_APP_ENABLE_CHECKOUT: ${process.env.REACT_APP_ENABLE_CHECKOUT}`)
        console.log(`REACT_APP_ENABLE_STRIPE_TEST_MODE: ${process.env.REACT_APP_ENABLE_STRIPE_TEST_MODE}`)
        if (process.env.REACT_APP_ENABLE_CHECKOUT?.toLowerCase() === 'true') {
            setEnableCheckoutProgress(true)
            await checkout(toPurchaseProduct)
            setEnableCheckoutProgress(false)
        } else {
            setEnableAlert(true);
        }
    };

    const handleAddToCartButton = async (product: Product) => {
        const productID = createProductIdentifier(product)
        addItem(
            {
                ...product,
                id: productID,

            }
        )
        setEnableShoppingCartDrawer(true)
    }

    const handleOnFavoriteButtonClick = async (generatedImage: GeneratedImage) => {
        const unsavedGeneratedImage = GeneratedImage.copyOf(generatedImage, updated => {
            // when isFavorite field is null then set it to true, otherwise, set it to the opposite of the existing value
            updated.isFavorite = generatedImage.isFavorite === null ? true : !generatedImage.isFavorite
        })
        const updatedGeneratedImage = await DataStore.save(unsavedGeneratedImage);
        setProductState({ ...productState, generatedImage: updatedGeneratedImage })
    }

    const handleOnRemixButtonClick = async (generatedImage: GeneratedImage) => {
        const imageResult = await generatedImage.imageResult;
        const options = { remixImage: generatedImage, prompt: imageResult!.prompt };
        // Set the current state as a path id param
        // Allows each product page to have a unique url
        navigate({
            pathname: `/generate/remix/${encodeBase64(JSON.stringify(options))}`,
        });
    }

    const checkout = async (checkoutProduct: Product) => {
        const apiName = 'pythonapi';
        const path = '/checkout';
        const payload = {
            body: {
                "products": [productToDict(checkoutProduct)],
                "success_redirect_url": `${window.location.origin}/checkout/success`,
                "cancel_redirect_url": window.location.href,
                "enable_test_mode": process.env.REACT_APP_ENABLE_STRIPE_TEST_MODE?.toLowerCase() === 'true'
            },
        };
        try {
            // Get user checkout link
            var response = await API.post(apiName, path, payload);
            // take response and navigate to the url
            // This is ugly but apparently window.open doesn't work for Safari or a few other browsers
            // https://stackoverflow.com/a/39387533
            // https://stackoverflow.com/a/11942772
            try { window.location.replace(response["checkout_session_url"]) }
            catch (e) { window.location = response["checkout_session_url"] }
        } catch (error: any) {
            toast.error("Oops something went wrong. Please try again")
        }
    }

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ToastContainer
                position="top-center"
                newestOnTop={false}
                autoClose={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Box sx={{ my: 3, mx: 2 }}>
                <Grid container spacing={0} justifyContent={"center"} alignContent={"center"}>
                    <Grid item xs={8}>
                        <Typography sx={{ m: 1 }} gutterBottom variant="h5" component="div">
                            Mounted Frame
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Remix">
                            <IconButton onClick={() => handleOnRemixButtonClick(productState.generatedImage)} aria-label="Remix image button">
                                <AutoFixHigh />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Favorite">
                            <IconButton onClick={() => handleOnFavoriteButtonClick(productState.generatedImage)} aria-label="favorite image button">
                                {productState.generatedImage.isFavorite
                                    ? <Favorite sx={{ color: "#a61a2e" }} />
                                    : <FavoriteBorderOutlined sx={{ color: "#222" }} />
                                }
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Typography sx={{ m: 1 }} color="text.secondary" variant="body1">
                    {productState.description}
                </Typography>
                <Typography sx={{ m: 1 }} gutterBottom variant="h5" component="div">
                    ${productToPrice(productState)} <Chip variant="outlined" color="info" size="small" label="+ shipping" />
                </Typography>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ m: 2 }}>
                <FormControl required sx={{ minWidth: 200 }}>
                    <InputLabel id="productdetails-frame-size-label">Frame Size</InputLabel>
                    <Select
                        labelId="productdetails-frame-size-label"
                        id="productdetails-frame-size-required"
                        value={productState.frameSize}
                        onChange={handleFrameSizeChange}
                        label="Frame Size"
                        inputProps={{ 'aria-label': 'Select Frame Size' }}>
                        <MenuItem value={'FRA-INSTA-30X30'}>12"x12" Mounted</MenuItem>
                        <MenuItem value={'FRA-INSTA-40X40'}>16"x16" Mounted</MenuItem>
                        <MenuItem disabled value={'Other sizes coming soon'}>Other sizes coming soon</MenuItem>
                    </Select>
                    <FormHelperText>Required</FormHelperText>
                </FormControl>
            </Box>
            <Box sx={{ m: 2 }}>
                <FormControl required sx={{ minWidth: 200 }}>
                    <InputLabel id="productdetails-frame-color-label">Frame Color</InputLabel>
                    <Select
                        labelId="productdetails-frame-color-label"
                        id="productdetails-frame-color-required"
                        value={productState.frameColor}
                        onChange={handleFrameColorChange}
                        label="Frame Color"
                        inputProps={{ 'aria-label': 'Select Frame Color' }}>
                        <MenuItem value={"Black"}>Black</MenuItem>
                        <MenuItem value={"White"}>White</MenuItem>
                    </Select>
                    <FormHelperText>Required</FormHelperText>
                </FormControl>
            </Box>
            <Box sx={{ m: 2 }}>
                <Alert severity="warning" icon={false}>
                    Kindly be advised that the actual product may vary from the displayed images. There is a no refund policy for the generated images since they are custom made.
                    See our <Link href="http://legal.wallai.art/refundAndReturns.html" target="_blank">
                        Refund / Returns policy
                    </Link> for more information.
                </Alert>
            </Box>
            <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                <Grid container>
                    <Grid item sx={{ ml: 1, mr: 2 }} xs={4} >
                        {enableCheckoutProgress
                            ? <CircularProgress />
                            : <Button variant="outlined" disabled={enableCheckoutProgress} onClick={() =>
                                handleOnClickBuyButton(productState)
                            }>Buy now</Button>}
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" onClick={() => handleAddToCartButton(productState)}>Add to cart</Button>
                    </Grid>
                </Grid>
                <React.Fragment key={"shopping-cart-drawer"}>
                    <SwipeableDrawer
                        anchor={isMobile ? "bottom" : "right"}
                        open={enableShoppingCartDrawer}
                        onClose={() => setEnableShoppingCartDrawer(false)}
                        onOpen={() => setEnableShoppingCartDrawer(true)}
                    >
                        <ShoppingCartPage sx={{ width: isMobile ? 'auto' : 375, height: isMobile ? 500 : 'auto' }} size="small" />
                    </SwipeableDrawer>
                </React.Fragment>
            </Box>
            {enableAlert && <Alert onClose={() => setEnableAlert(false)} severity="info">The ability to Buy is under maintenance while we improve performance. BRB!</Alert>}
        </Box>
    );
}

type ProductImageCarouselComponentProps = {
    url: string;
    alt: string;
    frameColor: string;
}

const ProductImageCarouselComponent = (props: ProductImageCarouselComponentProps) => {
    return (
        <Grid container justifyContent="center">
            <Paper sx={{
                height: 300,
                width: 300,
            }} elevation={10}>
                <div className={styles.photoFrame}>
                    <Zoom>
                        <img
                            alt={props.alt}
                            src={props.url}
                            width={300}
                        />
                    </Zoom>
                </div>
            </Paper>
        </Grid>
    );
}

// TODO: I'd rather this return a key-value mapping of color to url but I keep getting type errors
const getFramedImages = (frameColor: string) => {
    if (frameColor.toLowerCase() === "white") {
        return "https://wallai-prod-images.s3.amazonaws.com/michaels-basic-white-8x8m5x5.jpeg"
    } else if (frameColor.toLowerCase() === "silver") {
        return "https://wallai-prod-images.s3.amazonaws.com/michaels-basic-silver-8x8m5x5.jpeg"
    } else {
        return "https://wallai-prod-images.s3.amazonaws.com/michaels-basic-black-8x8m5x5.jpeg"
    }
}

type ResizableMergedImage = {
    src: string;
    image?: Image;
    resizeHeight: number;
    resizeWidth: number;
    mergeRelativeXPosition: number;
    mergeRelativeYPosition: number;
}

const resizeImage = async (image: Image, width: number, height: number): Promise<string> => {
    const resizedImg = image.resize({
        width: width,
        height: height,
    });

    // convert image-js image to a canvas
    const canvas = resizedImg.getCanvas();

    // convert canvas to a data url
    const dataURL = canvas.toDataURL();
    return dataURL
};

interface MergeImages {
    img1: ResizableMergedImage,
    img2: ResizableMergedImage
}

const resizeAndMergeImages = async ({ img1, img2 }: MergeImages) => {
    const image1 = (await Image.load(img1.src, { mode: "cors", ignorePalette: false })).getCanvas().toDataURL();
    const resizedImage2 = await resizeImage(img2.image!, img2.resizeWidth, img2.resizeHeight);

    const mergedImage = await mergeImages([
        { src: image1, x: img1.mergeRelativeXPosition, y: img1.mergeRelativeYPosition },
        { src: resizedImage2, x: img2.mergeRelativeXPosition, y: img2.mergeRelativeYPosition }
    ])
    return mergedImage
}

const ProductComponentView = (props: ProductComponentViewProps) => {
    const [productState, setProductState] = useState<Product>({ ...props, frameColor: "Black" });
    const [mergedImages, setMergedImages] = useState<any[]>([])
    const mockupImages = [
        {
            img1: {
                src: getFramedImages(productState.frameColor ?? "black"),
                resizeHeight: 300,
                resizeWidth: 300,
                mergeRelativeXPosition: 0,
                mergeRelativeYPosition: 0
            },
            img2: {
                src: props.generatedImage.sourceUrl,
                resizeHeight: 215,
                resizeWidth: 215,
                mergeRelativeXPosition: 165,
                mergeRelativeYPosition: 175
            }
        },
        {
            img1: {
                src: "https://wallai-prod-images.s3.amazonaws.com/mockup-8.png",
                resizeHeight: 300,
                resizeWidth: 300,
                mergeRelativeXPosition: 0,
                mergeRelativeYPosition: 0
            },
            img2: {
                src: props.generatedImage.sourceUrl,
                resizeHeight: 250,
                resizeWidth: 250,
                mergeRelativeXPosition: 268,
                mergeRelativeYPosition: 260
            }
        },
        {
            img1: {
                src: "https://wallai-prod-images.s3.amazonaws.com/mockup-6.png",
                resizeHeight: 300,
                resizeWidth: 300,
                mergeRelativeXPosition: 0,
                mergeRelativeYPosition: 0
            },
            img2: {
                src: props.generatedImage.sourceUrl,
                resizeHeight: 100,
                resizeWidth: 100,
                mergeRelativeXPosition: 253,
                mergeRelativeYPosition: 238
            }
        },
        {
            img1: {
                src: "https://wallai-prod-images.s3.amazonaws.com/mockup-2.png",
                resizeHeight: 300,
                resizeWidth: 300,
                mergeRelativeXPosition: 0,
                mergeRelativeYPosition: 0
            },
            img2: {
                src: props.generatedImage.sourceUrl,
                resizeHeight: 65,
                resizeWidth: 65,
                mergeRelativeXPosition: 367,
                mergeRelativeYPosition: 237
            }
        }]

    // This useEffect hook is responsible for creating merged images by combining
    // the product's frame color with the generated image's source URL.
    // It resizes, merges, and sets the merged images in the state.
    React.useEffect(() => {
        // Async function to create merged images from the provided list
        const createMergedImage = async (imagesToMerge: MergeImages[]) => {
            // Add the query param to prevent Chrome caching and avoid CORS error
            const blockedCacheUrl = `${props.generatedImage.sourceUrl}?cacheBlock=true`
            const generatedImage = await Image.load(blockedCacheUrl, { mode: "cors", ignorePalette: false });
            const imgs = await Promise.all(imagesToMerge.map(async (item) => {
                item.img2.image = generatedImage
                return await resizeAndMergeImages(item)
            }))
            setMergedImages(
                imgs.map((image: string, index) => {
                    return (
                        <Grid key={index} container justifyContent="center">
                            <Paper sx={{
                                height: 300,
                                width: 300,
                            }} elevation={10}>
                                <Zoom>
                                    <img alt="" style={{ height: 300, width: 300 }} src={image} />
                                </Zoom>
                            </Paper>
                        </Grid>
                    )
                }))
        }

        // Set the product state and create merged images using the mockupImages array
        setProductState(props)
        createMergedImage(mockupImages) // Using the mockupImages defined outside of the useEffect
        // mockupimages is a static constant so eslint is complaining about it not being passed in
        // even if we did pass it in, it is non-primitive and wouldn't ever pass the reference equality check
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.generatedImage.sourceUrl]) // Only re-run when the generatedImage's source URL changes

    const handleProductDetailsChange = (product: Product) => {
        setProductState(product)
    }

    return (
        <Grid container justifyContent="center" sx={{
            paddingTop: 2,
            paddingBottom: 2,
            paddingRight: 2,
            marginTop: 2,
            marginLeft: "auto",
            marginRight: "auto",
        }}>
            <Stack justifyContent="center" direction="column" spacing={2}>
                {mergedImages === undefined || mergedImages.length === 0 ?
                    <Grid container justifyContent="center">
                        <CircularProgress />
                    </Grid>

                    : <Carousel autoPlay={false} sx={{
                        height: 400,
                        width: 350,
                        paddingTop: 5,
                        marginTop: 2,
                    }}>
                        {[
                            <ProductImageCarouselComponent key={props.generatedImage.sourceUrl} url={props.generatedImage.sourceUrl} alt={props.description} frameColor={productState.frameColor!} />,
                            ...mergedImages
                        ]}
                    </Carousel>}
                <ProductDetailsComponent product={productState} onChange={handleProductDetailsChange} />
            </Stack>
        </Grid >
    );
}

const ProductComponentViewWrapper = () => {
    const params = useParams();
    const props = JSON.parse(decodeBase64(params.productState!)) as ProductComponentViewProps;
    // TODO: add better error handling
    return <ProductComponentView {...props} />;
};

export default ProductComponentViewWrapper;
