import * as React from 'react';
import { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Send, Tune } from '@mui/icons-material';
import { ChipInputTextField } from './components/ChipInputTextField';
import { SuggestionGrid } from './components/SuggestionGrid';
import { Box, Collapse } from '@mui/material';
import { LinearIndeterminate } from './components/LinearIndeterminate';
import { isEmptyString } from './util';
import { AIGeneratedAlternativePromptsGrid } from './components/AIGeneratedAlternativePromptGrid';
import { selectRandomItems } from './util';
import { isMobile } from 'react-device-detect';
import { StyleToolsWrapper } from './components/StyleToolsWrapper';
import { PromptHelperStyleImages } from './models';

interface TextToImageTextFieldProps {
  prompt: string;
  onGenerate: any;
  generateButtonText?: string;
  placeholder?: string;
  disablePromptSuggestions?: boolean;
  alternativePrompts?: string[];
}

type TextToImageTextFieldState = {
  prompt: string;
  isGeneratingResults: boolean;
  isValidPrompt: boolean;
  showGenerateTools: boolean;
  promptModifiers: PromptHelperStyleImages[]
  alternativePrompts?: string[];
}

const TextToImageTextField = (props: TextToImageTextFieldProps) => {
  // Need to initialize prompt like this b/c https://stackoverflow.com/a/59308313
  const [state, setState] = useState<TextToImageTextFieldState>({
    prompt: props.prompt,
    isGeneratingResults: false,
    isValidPrompt: false,
    showGenerateTools: false,
    promptModifiers: [],
    alternativePrompts: props.alternativePrompts ?? [],
  })
  const [showSuggestionGrid, setShowSuggestionGrid] = useState<boolean>(false)
  const suggestions = [
    "A beautiful summer night sky with floating buildings, by Monet, watercolour painting.",
    "A bustling city street at dawn, bathed in the soft glow of street lamps, in the style of Van Gogh, oil painting.",
    "An ancient forest filled with mythical creatures and glowing flowers, inspired by Tolkien’s Middle-Earth, pencil sketch.",
    "A futuristic skyline with towering skyscrapers, flying cars, and neon lights, echoing Blade Runner's aesthetic, digital art."
  ]

  const disableSuggestionGrid = (disablePromptSuggestions: boolean | undefined, prompt: string) => {
    return (
      (disablePromptSuggestions !== undefined
        && disablePromptSuggestions)
        // when props.disablePromptSuggestions is passed in as true, then return true
        ? disablePromptSuggestions
        // when props.disablePromptSuggestions is not passed in or is false, then return true if prompt is not empty
        : !isEmptyString(prompt)
    )
  }

  const constructPrompt = (prompt: string, promptModifiers: PromptHelperStyleImages[]) => {
    return `${prompt}${(promptModifiers.length !== 0) ? (", in the style of " + promptModifiers.map((p) => p.displayText).join(", ")) : ""}`
  }

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const prompt = event.target.value;
    setState({
      ...state,
      prompt: prompt,
      isValidPrompt: !isEmptyString(prompt),
    })
  }

  const handleClearButtonClick = () => {
    setState({
      ...state,
      prompt: "",
      promptModifiers: [],
      isValidPrompt: false,
    })
  }

  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      // The page will refresh if we don't stopPropagation & preventDefault
      event.stopPropagation();
      event.preventDefault();
      // Only allow user to generate new results when no current results are being generated and the prompt is valid
      if (!state.isGeneratingResults && state.isValidPrompt) {
        setState({
          ...state,
          isGeneratingResults: true
        })
        // on key enter same as pushing generate button
        const promptWithModifiers = constructPrompt(state.prompt, state.promptModifiers)
        await props.onGenerate(promptWithModifiers)
        setState({
          ...state,
          prompt: promptWithModifiers,
          isGeneratingResults: false,
        })
      }
    }
  }

  const generatePrompt = async (prompt: string) => {
    setState({
      ...state,
      prompt: prompt,
      isGeneratingResults: true,
    })
    await props.onGenerate(prompt)
    setState({
      ...state,
      prompt: prompt,
      isGeneratingResults: false
    })
  }

  const handleGenerateButtonClick = async () => {
    if (!state.isGeneratingResults && state.isValidPrompt) {
      const promptWithModifiers = constructPrompt(state.prompt, state.promptModifiers)
      await generatePrompt(promptWithModifiers)
    }
  }
  const handleStyleImageSelectChange = (styles: PromptHelperStyleImages[]) => {
    setState({
      ...state,
      promptModifiers: styles,
      showGenerateTools: !state.showGenerateTools
    })
  }

  const handleStyleDeletedFromChipInput = (style: PromptHelperStyleImages) => {
    const promptModifiers = state.promptModifiers.filter((element) => element.id !== style.id)
    setState({
      ...state,
      promptModifiers: promptModifiers
    })
  }

  const handleClickableCardClick = async (body: string) => {
    if (!isEmptyString(body)) {
      const promptWithModifiers = constructPrompt(body, state.promptModifiers)
      await generatePrompt(promptWithModifiers)
    }
  };

  useEffect(() => {
    setState(s => ({
      ...s,
      prompt: props.prompt,
      isValidPrompt: !isEmptyString(props.prompt),
      alternativePrompts: props.alternativePrompts ?? [],
    }))
  }, [props.prompt, props.alternativePrompts])

  // updates the isValidPrompt state when the prompt changes
  useEffect(() => {
    setState(s => ({
      ...s,
      isValidPrompt: !isEmptyString(s.prompt),
    }))
  }, [state.prompt])

  useEffect(() => {
    const disablePromptSuggestions = disableSuggestionGrid(props.disablePromptSuggestions, state.prompt)
    setShowSuggestionGrid(!disablePromptSuggestions)
  }, [props.disablePromptSuggestions, state.prompt])

  return (
    <Stack spacing={2} direction="column">
      <Collapse in={showSuggestionGrid}>
        <SuggestionGrid
          suggestions={selectRandomItems(suggestions, isMobile, 2)} // only show 2 suggestions on mobile
          onClick={handleClickableCardClick}
        />
      </Collapse>
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" }}
      >
        <ChipInputTextField
          disabled={state.isGeneratingResults}
          value={state.prompt}
          placeholder={
            props.placeholder
              ? props.placeholder
              : "Type anything you can dream of in whatever style of art you want displayed on your wall."
          }
          chipData={state.promptModifiers.map((style: PromptHelperStyleImages) => ({ style, color: 'primary' }))}
          onChipDelete={handleStyleDeletedFromChipInput}
          onTextChange={handleTextFieldChange}
          onKeyDown={handleKeyPress}
          InputProps={{
            endAdornment: (<IconButton disabled={state.isGeneratingResults} sx={{ visibility: state.prompt ? "visible" : "hidden" }} onClick={handleClearButtonClick}><ClearIcon /></IconButton>)
          }}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton disabled={state.isGeneratingResults || !state.isValidPrompt} onClick={handleGenerateButtonClick} color="primary" sx={{ p: '10px' }} aria-label="directions">
          <Send />
        </IconButton>
      </Paper>
      {/* Only show prompt toolbar when suggestion grid is not showing */}
      <Box display="flex" justifyContent="flex-end" m={1}>
        <StyleToolsWrapper
          disabled={state.isGeneratingResults || isEmptyString(state.prompt)}
          isOpen={false}
          handleStyleImageChange={handleStyleImageSelectChange} />
        {state.alternativePrompts && state.alternativePrompts.length > 0
          // display alternative prompts if they exist
          ? <AIGeneratedAlternativePromptsGrid
            alternativePrompts={state.alternativePrompts}
            onClick={handleClickableCardClick} />
          : <div></div>
        }
      </Box>
      {state.isGeneratingResults ? <LinearIndeterminate /> : <div></div>}
    </Stack >
  )
}

export default TextToImageTextField
