import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ClickableCard from "./ClickableCard";

interface SuggestionGridProps {
    suggestions: string[];
    onClick: (body: string) => void;
}

export const SuggestionGrid = (props: SuggestionGridProps) => {
    return (
        <Box m={1}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Get Inspired with These Examples
                        <Tooltip title="Click on a prompt to see how it works and explore similar creations.">
                            <IconButton>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {props.suggestions.map((suggestion: string) => (
                    <Grid item xs={12} md={6} key={suggestion}>
                        <ClickableCard
                            body={suggestion}
                            onClick={props.onClick}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
