import { Buffer } from 'buffer';
import { DataStore, Predicates, SortDirection } from 'aws-amplify';
import { GeneratedImage, ImageResult, InitImage, PublicImage } from './models';

const decodeBase64 = (str: string): string => Buffer.from(str, 'base64').toString('utf8');
const encodeBase64 = (str: string): string => Buffer.from(str, 'utf8').toString('base64');

export { decodeBase64, encodeBase64 }

interface saveTextToImageResultParams {
    prompt: string;
    imageUrls: string[];
    initImage?: GeneratedImage,
    aiGeneratedAlternativePrompts?: string[];
}

export const isEmptyString = (s: string): boolean => {
    // There's something strange about how typescript interprets
    // this conditional so we explicitly return True or False
    // Typescript interprets this conditional as returning either a
    // string or a boolean
    if (s && s.trim().length !== 0) {
        return false
    } else {
        return true
    }
}

const saveTextToImageResult = async (params: saveTextToImageResultParams) => {
    // We explicitly set the createdAt timefields since amplify datastore which should
    // be setting these values for us isn't. There is likely a bug with the latest version
    // of the software which is causing this issue, unfortunately.
    const currentTimestamp = new Date().toISOString()
    const imageResult = await DataStore.save(new ImageResult({
        prompt: params.prompt,
        imageUrls: params.imageUrls,
        createdAt: currentTimestamp,
        updatedAt: currentTimestamp,
        aiGeneratedAlternativePrompts: params.aiGeneratedAlternativePrompts
    }))

    // Create the generated image object with a reference to the image result created above
    const savedGeneratedImages: GeneratedImage[] = await Promise.all(
        params.imageUrls.map(async (imageUrl: string) => {
            const generatedImageCurrentTimestamp = new Date().toISOString()
            const generatedImage = await DataStore.save(new GeneratedImage({
                imageResult: imageResult,
                sourceUrl: imageUrl!,
                // Default isFavorite to false. We explicitly write this because if we don't
                // then is value is set to null for some reason even though the schema.graphql file
                // shows that this has a default value.
                isFavorite: false,
                createdAt: generatedImageCurrentTimestamp,
                updatedAt: generatedImageCurrentTimestamp,
                prompt: params.prompt
            }));

            return generatedImage
        }));

    // Save initial image if used
    if (params.initImage) {
        const initImage = await DataStore.query(GeneratedImage, params.initImage.id)
        const savedInitImage = await DataStore.save(new InitImage({
            imageResult: imageResult,
            generatedImage: initImage!,
            prompt: params.prompt,
        }))
        return ImageResult.copyOf(imageResult, updated => {
            updated.generatedImages = savedGeneratedImages;
            updated.initImage = savedInitImage
        });
    } else {
        return ImageResult.copyOf(imageResult, updated => {
            updated.generatedImages = savedGeneratedImages
        });
    }
}

const getTextToImageResult = async (imageResultID: string) => {
    const result = await DataStore.query(ImageResult, imageResultID)
    return result
}

const getGeneratedImage = async (generatedImageID: string) => {
    const result = await DataStore.query(GeneratedImage, generatedImageID)
    return result
}

interface getTextToImageResultParams {
    page: number;
    limit: number;
    onError: any
}

// TODO: This is awful and needs to be rewritten but is supporting legacy data schemas because I haven't backfilled
// all other data yet. This should be done.
const getTextToImageResults = async ({ page, limit = 10, onError }: getTextToImageResultParams) => {
    try {
        const results = await DataStore.query(
            ImageResult,
            Predicates.ALL,
            {
                page: page,
                limit: limit,
                sort: r => r.createdAt(SortDirection.DESCENDING)
            }
        );
        // TODO: remove this after a proper database migration occurrs
        // Unfortunately, this is still an open issue with amplify graphql schemas
        // https://github.com/aws-amplify/amplify-cli/issues/1407
        // Probably should write a database migration instead of having this here but
        // this is a quick fix in the meantime
        const updatedImageResults = await Promise.all(results.map(async (imageResult) => {
            // Check if imageResult is missing the new generatedImages field.
            // If it is missing then create it and save to the datastore
            // TODO: fix this issue with the copyOF and this causing the page to freeze
            const generatedImages = await imageResult?.generatedImages?.toArray();
            if (!generatedImages.length) {
                const queriedGeneratedImages = await DataStore.query(GeneratedImage, (g) => g.imageResult.id.eq(imageResult.id))
                if (!queriedGeneratedImages) {
                    // Create the generated image object with a reference to the image result created above
                    const savedGeneratedImages: GeneratedImage[] = await Promise.all(
                        imageResult.imageUrls!.map(async (imageUrl) => {
                            const generatedImage = await DataStore.save(new GeneratedImage({
                                imageResult: imageResult,
                                sourceUrl: imageUrl!,
                                // Default isFavorite to false. We explicitly write this because if we don't
                                // then is value is set to null for some reason even though the schema.graphql file
                                // shows that this has a default value.
                                isFavorite: false,
                                prompt: imageResult.prompt,
                            }));

                            return generatedImage
                        }));
                    return ImageResult.copyOf(imageResult, updated => {
                        updated.generatedImages = savedGeneratedImages
                    })

                } else {
                    if (queriedGeneratedImages.length > 4) {
                        await deleteDuplicatedGeneratedImages(queriedGeneratedImages);
                    }
                    return imageResult
                }
            } else {
                if (generatedImages.length > 4) {
                    await deleteDuplicatedGeneratedImages(generatedImages);
                }
                return imageResult
            }
        }))

        return updatedImageResults
    } catch (error) {
        onError(error)
    }
}

const getFavoriteGeneratedImages = async () => {
    const results = await DataStore.query(
        GeneratedImage,
        (i) => i.isFavorite.eq(true)
    );
    return results
}

const deleteDuplicatedGeneratedImages = async (images: GeneratedImage[]) => {
    const grouped = images.reduce((groups, image) => {
        const key = image.sourceUrl;
        // if no entry for key then add it to grouped
        // if entry for key but current entry createdAt field is null or undefined then add new image to group
        // if entry for key but new image created at is less than current entry then add it to group
        if (!groups.has(key)
            || (image?.createdAt && !groups.get(key)?.createdAt)
            || (image?.createdAt && groups.get(key)?.createdAt && groups.get(key)!.createdAt! > image.createdAt)) {
            groups.set(key, image);
        }
        return groups;
    }, new Map<string, GeneratedImage>());

    const itemsToDelete = images.filter(image => {
        const item = grouped.get(image.sourceUrl)
        return !(image.id === item!.id)
    });
    await Promise.all(itemsToDelete.map(image => DataStore.delete(GeneratedImage, image.id)));
}

type CreateGeneratedImageFromPublicImageOutputType = {
    imageResult: ImageResult,
    generatedImage: GeneratedImage
}

const createGeneratedImageFromPublicImage = async (publicImage: PublicImage): Promise<CreateGeneratedImageFromPublicImageOutputType> => {
    try {
        const existingImages = await DataStore.query(GeneratedImage, (img) => img.sourceUrl.eq(publicImage.sourceUrl));

        if (existingImages && existingImages.length > 0) {
            // If the image already exists, return it
            const generatedImage = existingImages[0];
            const imageResult = await generatedImage.imageResult;
            if (!imageResult) {
                console.error('ImageResult not found for GeneratedImage');
            }
            return { imageResult: imageResult!, generatedImage: generatedImage };
        }

        const currentTimestamp = new Date().toISOString()
        // Create an ImageResult
        const imageResult = await DataStore.save(
            new ImageResult({
                // Set the prompt to match the PublicImage prompt
                prompt: publicImage.prompt,
                // Initialize the imageUrls to an empty array. Update this as needed.
                imageUrls: [],
                createdAt: currentTimestamp,
                updatedAt: currentTimestamp,
            })
        );

        // Create a GeneratedImage
        const generatedImage = await DataStore.save(
            new GeneratedImage({
                // Set the sourceUrl to match the PublicImage sourceUrl
                sourceUrl: publicImage.sourceUrl,
                // Set the prompt to match the PublicImage prompt
                prompt: publicImage.prompt,
                // Set the ImageResult relationship
                imageResult: imageResult,
                // Initialize isFavorite to false
                isFavorite: false,
                createdAt: currentTimestamp,
                updatedAt: currentTimestamp,
            })
        );

        // Return the created instances
        return { imageResult, generatedImage };

    } catch (error) {
        console.error('Error creating ImageResult and GeneratedImage:', error);
        throw error;
    }
}

/**
 * Selects a specific number of random elements from an array.
 * If the requested size is greater than the length of the array, the full array is returned.
 * If the condition flag is false, the full array is also returned.
 *
 * @param {T[]} items - The array of items.
 * @param {boolean} condition - A flag to determine whether to apply the size restriction.
 * @param {number} count - The number of random elements to select.
 * @returns {T[]} The selected items.
 */
export const selectRandomItems = <T>(items: T[], condition: boolean, count: number): T[] => {
    if (condition && count < items.length) {
        const randomIndices = new Set<number>();
        while (randomIndices.size < count) {
            randomIndices.add(Math.floor(Math.random() * items.length));
        }
        return Array.from(randomIndices).map(index => items[index]);
    }
    return items;
};

export { createGeneratedImageFromPublicImage, saveTextToImageResult, getTextToImageResult, getTextToImageResults, getFavoriteGeneratedImages, getGeneratedImage }
